import { BaseLayout } from '@components/Layout';
import { t } from '@lingui/macro';
import { FlowContainer, LoginOptions, ReactFC } from '@milo/react';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

export const LoginContainer: ReactFC = ({ children, ...props }) => (
  <div className="relative top-[10vh] flex items-center justify-center" {...props}>
    <div className="w-screen max-w-lg rounded-2xl p-12 px-10 shadow-lg">{children}</div>
  </div>
);

export default function LoginPage() {
  const { query } = useRouter();
  return (
    <BaseLayout showNavigation={false} showBanner={false} stickyNavigation showFooter={false} saveRedirect={false}>
      <FlowContainer>
        <LoginOptions error={query?.status === 'error'} />
      </FlowContainer>
    </BaseLayout>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      seo: { title: t`Connexion`, description: t`Connectez vous à M ta région` },
    },
  };
};
